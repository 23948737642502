import React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getTask, getSelectedTask } from '../selectors';
import {
	taskCompleted,
	taskUpdated,
	taskDateChanged,
	taskTimeChanged,
	taskBlocksChanged
} from '../actions/tasks';

const BlockButton = styled.div`
	width: 8px;
	height: 8px;
`;

class TaskInfo extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			hovered: []
		}
	}

  handleDateChange = (date) => {
    const { task, taskDateChanged } = this.props;
    taskDateChanged(task, date);
  }

  handleTimeChange = (time) => {
    const { task, taskTimeChanged } = this.props;
    taskTimeChanged(task, time);
  }

  handleBlocksChange = (blocks) => {
    const { task, taskBlocksChanged } = this.props;
		taskBlocksChanged(task, blocks);
  }

	addHovered = (i) => {
		let newHovered = [...this.state.hovered];
		newHovered.push(i);
		this.setState({hovered: newHovered});
	}

	removeHovered = (i) => {
		this.setState({hovered: this.state.hovered.filter(e => e !== i)});
	}

	renderBlockSelector = (blocks) => {
		const rows = 3;
		const cols = 8;
		const blockWidth = 12
		const padding = 4
		const totalBlockWidth = blockWidth + padding;
		const viewWidth = cols * totalBlockWidth + padding * 2;
		const viewHeight = rows * totalBlockWidth;
		return (
			<svg
				width={'100%'}
				height={"100%"}
				viewBox={`0 0 ${viewWidth} ${viewHeight}`}
			>
				{[...Array(cols * rows).keys()].map((i) => {
					let x = (i % cols) * totalBlockWidth + (i % cols > 3 ? padding * 2 : 0);
					let y = Math.floor(i / cols) * totalBlockWidth;
					return (
						<React.Fragment key={i}>
							<rect x={x} y={y} width={blockWidth} height={blockWidth}
								fill={`${i < blocks ? 'hsl(204, 86%, 53%)' : 'hsl(0, 0%, 86%)'}`}
							/>
							<rect x={x} y={y} width={totalBlockWidth + (i % cols == 3 ? padding * 2 : 0)} height={totalBlockWidth}
								fill={"white"} opacity="0"
								onMouseOver={() => this.addHovered(i + 1)}
								onMouseLeave={() => this.removeHovered(i + 1)}
								onClick={() => {this.handleBlocksChange(i + 1)}}
							/>
						</React.Fragment>
					);
				})}
			</svg>
		);
	}

  render() {
    const {task, taskCompleted, taskUpdated } = this.props;
		const { title, completed, blocks, startDate, startTime } = task;
		const blocksDisplayed = this.state.hovered.length > 0 ? Math.max(...this.state.hovered) : blocks;
    return (
      <div className="box">
      <h1 className="title">{title}</h1>
      <div className='columns is-mobile'>
        <div className='column'>{`blocks: ${blocksDisplayed}`}</div>
        <div className='column'>
					{this.renderBlockSelector(blocksDisplayed)}
        </div>
      </div>
      <div className='level is-mobile'>
        <div className='level-left'>Start Date:</div>
        <div className='level-right'>
          <DatePicker
            selected={startDate ? startDate : null}
            onSelect={this.handleDateChange}
            placeholderText="Select a date"
          />
        </div>
      </div>
      <div className='level is-mobile'>
        <div className='level-left'>Start Time:</div>
        <div className='level-right'>
          <DatePicker
            selected={startTime ? startTime : null}
            onChange={this.handleTimeChange}
            showTimeSelect
            showTimeSelectOnly
            dateFormat="hh:mm aa"
            placeholderText="Select a time"
            timeIntervals={15}
          />
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.useSelected)
    return { task: getSelectedTask(state) }
  return { tasks: getTask(state, ownProps.taskId) };
};

const mapDispatchToProps = {
	taskCompleted,
	taskUpdated,
	taskDateChanged,
	taskTimeChanged,
	taskBlocksChanged
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskInfo);
