import React from 'react';
import { connect } from 'react-redux';
import { getTaskIds } from '../selectors';
import { taskSelected } from '../actions/tasks';

function DayPlanner(props) {
  const { tasks, selectedTaskId, taskSelected } = props;
  return (
    <div className="columns box is-mobile">yo</div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    tasks: getTaskIds(state, ownProps.filter),
    selectedTaskId: state.selectedTaskId
  };
};

const mapDispatchToProps = {
  taskSelected
}

export default connect(mapStateToProps, mapDispatchToProps)(DayPlanner);
