import React from 'react';
import { Link } from "react-router-dom";

function Header() {
  return (
    <nav className="Header level">
      <div className="level-left">
        <Link to="/">Home</Link>
        <Link to="/board">Board</Link>
      </div>
    </nav>
  );
}

export default Header;
