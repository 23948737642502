export const getMainBacklogTasks = state => state.tasks;
export const getTaskIds = (state, filter) => {
	const ids = Object.keys(state.tasks);
	if (!filter)
		return ids;
	return (ids.filter((id) => {
		let task = state.tasks[id];
		if (filter.hasOwnProperty('completed') && !!task.completed !== filter.completed)
			return (false);
		if (filter.hasOwnProperty('startTime') && !!task.startTime !== filter.startTime)
			return (false);
		if (filter.hasOwnProperty('startDate'))
		{
			if (typeof filter.startDate === 'object'){
				if (!task.startDate || task.startDate.toDateString() !== filter.startDate.toDateString())
					return (false);
			} else {
				if (!!task.startDate !== filter.startDate)
					return (false);
			}
		}
		return (true);
	}));
};
export const getTask = (state, id) => state.tasks[id];
export const getTaskTitle = (state, id) => {
	const task = state.tasks[id];
	if (task)
		return task.title;
	else return task;
};
export const getSelectedTask = (state) => {
	return state.tasks[state.selectedTaskId]
};
