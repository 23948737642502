export const TASK_COMPLETED = 'TASK_COMPLETED';
export const TASK_UPDATED = 'TASK_UPDATED';
export const TASK_SELECTED = 'TASK_SELECTED';
export const TASK_ADDED = 'TASK_ADDED';

export const taskCompleted = (task) => ({
  type: TASK_COMPLETED,
  id: task.id
});
export const taskUpdated = (task, update) => ({
	type: TASK_UPDATED,
	id: task.id,
	task,
	update,
});
export const taskSelected = (taskId) => ({
  type: TASK_SELECTED,
  id: taskId
});
export const taskAdded = (task) => ({
  type: TASK_ADDED,
  task,
});
export const taskDateChanged = (task, date) => taskUpdated(task, {startDate: date});
export const taskTimeChanged = (task, time) => taskUpdated(task, {startTime: time});
export const taskBlocksChanged = (task, blocks) => taskUpdated(task, {blocks});
export const addTask = (task) => taskAdded(task);
