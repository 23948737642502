import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Header from './components/Header';
import Home from './containers/HomeContainer';
import Board from './containers/BoardContainer';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Route path="/" exact component={Home} />
        <Route path="/board" component={Board} />
      </Router>
    </div>
  );
}

export default App;
