import { SCHEDULER_DATE_CHANGED } from '../actions/scheduler';

const initialState = {
  date: new Date()
}

const tasks = (state = initialState, action) => {
	switch (action.type){
    case SCHEDULER_DATE_CHANGED:
      return {...state, date: action.date};
	default:
		return state;
  }
};

export default tasks;
