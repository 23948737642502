import React from 'react';
import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import app from '../firebaseInterface';

const uiConfig = {
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        //Forces account selection even when one account is available.
        prompt: 'select_account'
      },
    }
  ],
  callbacks: {
    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      console.log("Auth Result", authResult, "RedirectUrl", redirectUrl);
      return false;
    }
  }
};

const ui = new firebaseui.auth.AuthUI(app.auth());
ui.start('#firebaseui-auth-container', uiConfig);

function Home(props) {
  return (
    <div className="Home">
      Home
			<div id="firebaseui-auth-container"></div>
    </div>
  );
}

export default Home;
