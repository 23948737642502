import uuidRandom from 'uuid/v4';
import { TASK_COMPLETED, TASK_UPDATED, TASK_ADDED } from '../actions/tasks';

const initialState = {
  a: {
	  id: 'a',
	  title: "taskA",
	  completed: false,
		blocks: 1,
		startDate: undefined,
		startTime: false,
  },
  b: {
	  id: 'b',
	  title: "taskB",
	  completed: false,
		blocks: 2,
		startDate: new Date(),
		startTime: false,
  },
  c: {
	  id: 'c',
	  title: "taskC",
	  completed: false,
		blocks: 4,
		startDate: new Date(),
		startTime: new Date(),
  },
  d: {
	  id: 'd',
	  title: "taskD",
	  completed: false,
		blocks: 2,
		startDate: false,
		startTime: false,
  },
}

function getDefaultTask() {
  return {
    id: uuidRandom(),
    title: "new task",
    completed: false,
    blocks: 1,
    startDate: false,
    startTime: false,
  }
}

const tasks = (state = initialState, action) => {
	let newState;
	switch (action.type){
    case TASK_ADDED:
      const { task } = action;
      const newTask = {...getDefaultTask(), ...task}
      return {...state, [newTask.id]:newTask }
    case TASK_COMPLETED:
	  	newState = {...state};
	  	newState[action.id] = {...(newState[action.id]), ...{completed: true}}
      return {...state, ...newState};
		case TASK_UPDATED:
			newState = {...state};
			newState[action.id] = {...(newState[action.id]), ...action.update}
			return {...state, ...newState}
	default:
		return state;
  }
};

export default tasks;
