import React from 'react';
import { connect } from 'react-redux';
import { getTaskTitle } from '../selectors';
import { taskSelected } from '../actions/tasks'

function TaskPanelBlock(props) {
  const { taskId, taskTitle, selectedTaskId, taskSelected } = props;
  const selected = taskId === selectedTaskId;
  return (
    <a className={`panel-block ${selected ? 'is-active' : ''}`} onClick={() => taskSelected(taskId)}>
      {/*

        <span class="panel-icon">
        <i class="fas fa-book" aria-hidden="true"></i>
        </span>
      */}
      {taskTitle}
    </a>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    taskTitle: getTaskTitle(state, ownProps.taskId),
    selectedTaskId: state.selectedTaskId,
  };
};

const mapDispatchToProps = {
  taskSelected
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskPanelBlock);
