import * as firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './firebaseConfig';
import { userAuthenticated } from './actions/user';

const app = firebase.initializeApp(firebaseConfig)
// const provider = new firebase.auth.GoogleAuthProvider();

app.auth().onAuthStateChanged((user) => {
  if(user) {
    userAuthenticated(user);
  } else {

  }
});

export default app;
