import React from 'react';
import MainBacklog from '../containers/MainBacklogContainer';
import SchedulerContainer from '../containers/SchedulerContainer';

function Board(props) {
  return (
    <div className="container">
      <section className="Board columns box container is-widescreen">
        <MainBacklog />
  			<SchedulerContainer />
      </section>
    </div>
  );
}

export default Board;
