import React from 'react';
import { connect } from 'react-redux';
import { getTaskIds } from '../selectors';
import TaskPanelBlock from './TaskPanelBlock';
import { addTask } from '../actions/tasks';

class TaskPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTaskTitle: ''
    };
  }

  handleChange = (e) => {
    this.setState({
      newTaskTitle: e.target.value
    });
    e.preventDefault();
  }

  handleSubmit = (e) => {
    const { filter, addTask } = this.props;
    e.preventDefault();
    if (this.state.newTaskTitle.length > 0)
    {
      addTask({
        title: this.state.newTaskTitle,
      });
      this.setState({
        newTaskTitle: ''
      });
    }
  }

  render() {
    const { tasks, title, noInput } = this.props;
    return (
      <div className="TaskBlockList panel">
        {title ?
          <p className="panel-heading">
            {title}
          </p>
        : '' }
        { noInput ? '' :
          <div className="panel-block">
            <form className="control" onSubmit={this.handleSubmit}>
              <input
                className="input"
                type="text"
                placeholder="new task"
                onChange={this.handleChange}
                value={this.state.newTaskTitle}
              />
            </form>
          </div>
        }
        {tasks.map((e) => <TaskPanelBlock taskId={e} key={e}/>)}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { tasks: getTaskIds(state, ownProps.filter) };
};

const mapDispatchToProps = {
  addTask
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskPanel);
