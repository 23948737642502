import { combineReducers } from 'redux';
import tasks from './tasks';
import schedulerReducer from './scheduler';
import { TASK_SELECTED } from '../actions/tasks';

const selectedTaskIdReducer = (state = 'a', action) => {
  switch(action.type){
    case TASK_SELECTED:
      return action.id;
    default:
      return state;
  }
};

export default combineReducers({
  selectedTaskId: selectedTaskIdReducer,
	scheduler: schedulerReducer,
	tasks
});
