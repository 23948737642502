import React from 'react';
import { connect } from 'react-redux';
import { getMainBacklogTasks } from '../selectors';
import TaskPanel from '../containers/TaskPanel';
import TaskInfo from '../containers/TaskInfo';
import { taskCompleted } from '../actions/tasks';

function MainBacklog(props) {
  return (
    <div className="MainBacklog column">
      <div className="level is-mobile">
        <div className='level-left'></div>
        <div className="level-right">
          <h1 className="title is-1">do</h1>
        </div>
      </div>
	  	<TaskPanel title="Main Backlog" filter={{
				startDate: false,
				completed: false
			}} />
      <TaskInfo useSelected />
    </div>
  );
}
//
// const mapStateToProps = (state) => {
//   return { tasks: getMainBacklogTasks(state) };
// };

export default MainBacklog;
// export default connect(mapStateToProps)(MainBacklog);
