import React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { connect } from 'react-redux';
import TaskPanel from '../containers/TaskPanel';
import DayPlanner from '../containers/DayPlanner';
import { schedulerDateChanged } from '../actions/scheduler';

const StrokeH1 = styled.h1`
	color: black;
	-webkit-text-fill-color: white;
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: black;
`;

class Scheduler extends React.Component {
	previous = () => {
		const { date, changeDate } = this.props;
		console.log('1', date);
		console.log('2', date.getDate() - 1)
		let newDate = new Date(date);
		newDate.setDate(date.getDate() - 1);
		changeDate(newDate);
	}

	next = () => {
		const { date, changeDate } = this.props;
		let newDate = new Date(date);
		newDate.setDate(date.getDate() + 1);
		changeDate(newDate);
	}

	render() {
		const { date, changeDate } = this.props;
		return (
			<div className="Scheduler column is-two-thirds">
				<div className="level is-mobile">
					<div className='level-left'>
						<StrokeH1 className="title is-1">allot</StrokeH1>
					</div>
					<div className="level-right"></div>
				</div>
				<div className="level is-mobile">
					<div className='level-left'>
						<h1 className='subtitle'>{date.toDateString()}</h1>
					</div>
					<div className="level-right">
						<button onClick={this.previous}>{"<"}</button>
						<DatePicker selected={date} onChange={changeDate} />
						<button onClick={this.next}>{">"}</button>
					</div>
				</div>
				<p>Backlog</p>
				<TaskPanel noInput filter={{
					startDate: date,
					startTime: false,
					completed: false
				}} />
				<p>Schedule</p>
				<DayPlanner filter={{
					startDate: date,
					startTime: true,
					completed: false
				}} />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	date: state.scheduler.date
});

const mapDispatchToProps = {
	changeDate: schedulerDateChanged,
};

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
